/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Image from '../../components/Image';
// Styles
import MediaBreakDown from '../../styles/MediaBreakDown';
// Constants
import theme from '../../styles/theme';
import constants from '../../constants';


const SliderWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto 0;
  background: ${theme.body.gray};
  ${MediaBreakDown.lg`
    margin: 30px auto 0;
  `}
`;
const Card = styled.div`
  background: ${props => props.bgColor};
  padding: 10px;
  position: relative;
  z-index: 1;
  margin: 15px 20px;
  width: calc(100% - 40px) !important;
  border-radius: 6px;
  outline: none;
  .text-wrap {
    background: ${theme.background.light};
    min-height: 230px;
    a {
      text-decoration: underline;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    text-align: center;
    padding: 20px;
  }
  h5 {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: ${theme.textColor.darkGrayText};
    text-align: center;
  }
  p {
    font-size: 18px;
    line-height: 21.6px;
  }
  ${MediaBreakDown.xl`
    .text-wrap {
      min-height: 270px;
    }
  `}
  ${MediaBreakDown.lg`
    margin: 15px 10px;
    width: calc(100% - 20px) !important;
    
    .text-wrap {
      min-height: 290px;
    }
  `}
  ${MediaBreakDown.md`
    .text-wrap {
      min-height: 240px;
    }
  `}
  @media(max-width: 370px) {
    height: 550px;
  }
`;
const ImageWrap = styled.div`
  background: ${props => props.bgColor};
  height: 220px;
  padding: 23px;
  text-align: center;
  .gatsby-image-wrapper {
    height: 174px;
  }
`;
const HowItWorks = () => {
  const settings = {
    infinite: true,
    autoPlay: true,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          className: 'center',
          centerMode: true,
          centerPadding: '20%',
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          className: 'center',
          centerMode: true,
          centerPadding: '10%',
        }
      }
    ]
  };


  return (
    <SliderWrap>
      <Slider {...settings}>
        <Card  bgColor={theme.background.yellow}>
          <div className="text-wrap">
            <h5>Join the program</h5>
            <div>
              <p>
                <a href={constants.URLS.AFFILIATE} target="_blank" rel="noreferrer">Apply now</a> to become a Workast affiliate.
              </p>
            </div>
          </div>
        </Card>
        <Card  bgColor={theme.background.blue}>
          <div className="text-wrap">
            <h5>Share your link</h5>
            <div>
              <p>
                Once you are accepted in the program, you will receive access to your unique affiliate link.
              </p>
            </div>
          </div>
        </Card>
        <Card  bgColor={theme.background.red}>
          <div className="text-wrap">
            <h5>Earn $100 USD</h5>
            <div>
              <p>
                Earn commisions for every new customer who subscribe to a paid plan.
              </p>
            </div>
          </div>
        </Card>
      </Slider>
    </SliderWrap>
  );
};

export default HowItWorks;
