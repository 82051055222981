const theme = {
  body: {
    dark: '#ffffff',
    light: '#595959',
    gray: '#F7F9FF',
  },
  heading: {
    dark: '#ffffff',
    light: '#3F3356',
  },
  background: {
    gray: '#e5e5e5',
    lightGray: '#f2f2f2',
    light: '#ffffff',
    blue: '#5986ff',
    darkBlue: '#375ABB',
    yellow: '#FFD05C',
    red: '#DD4B4B',
  },
  navbar: {
    dark: '#1F2432',
    light: '#ffffff',
  },
  textColor: {
    blackText: '#000000',
    lightBlack: '#202020',
    darkGrayText: '#262626',
    blueText: '#2F80ED',
    suvaGray: '#8c8c8c',
    silverText: '#C4C4C4',
    linkText: '#2753D7',
    tabText: '#1B3477',
    white90: '#f2f2f2',
    white: '#FFFFFF',
  },
  borderColor: {
    silver: '#C4C4C4',
    blueBorder: '#5986ff',
    grayBorder: '#e5e5e5',
    lightGray: '#E9E9E9',
    darkGray: '#424859',
    shadeGray: '#dddddd',
  },
  blockquote: {
    dark: '#f6f6f6',
  },
  icon: {
    blueIcon: '#5986FF',
    redIcon: '#DD4B4B',
    whiteIcon: '#FFFFFF',
    greenIcon: '#2BCA86',
  },
  hover: {
    hoverBlue: '#5986ff',
    hoverBlack: '#000000',
    hoverLight: '#4d74dc',
  },
  priceColor: {
    essential: '#FFD05C',
    standard: '#2BCA86',
    professional: '#5986FF',
    enterprise: '#DD4B4B',
    price: '#2C3346',
  },
  table: {
    dark: '#ffffff',
    light: 'rgba(89,134,255,0.05)',
  },
  boxShadow: {
    light: 'rgba(0, 0, 0, 0.15)',
  },
  font: {
    paragraph: {
      fontSize: '22px',
      lineHeight: '36px',
    },
    smallParagraph: {
      fontSize: '18px',
      lineHeight: '32px',
    },
    quote: {
      fontSize: '36px',
      lineHeight: '54px',
    }
  },
};

export default theme;
